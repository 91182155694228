import {createRouter, createWebHistory} from 'vue-router'

import routes from '@/router/routes'
// import routes_example from '@/router/routes_example'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  var title = to.matched[to.matched.length - 1].props.default.label
  document.title = 'میتیو | ' + title
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   // eslint-disable-next-line no-undef
  //   if (!localStorage.getItem('token')) {
  //     next({
  //       path: '/login',
  //       query: { redirect: to.fullPath },
  //     })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
})
export default router
