<template>
    <div class="container py-5">
      <div class="text-center">
        <img src="icons/app-development.png" width="100" class="mb-4 mx-auto" alt="در حال توسعه">
      </div>
      <h3 class="text-center">
        وب سایت درحال توسعه میباشد.
      </h3>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
}
</script>

<style scoped>

</style>