import Master from "@/components/Master";


var routes = [
  {
    path: '/',
    name: 'Home',
    props: {
      label: 'صفحه اصلی',
    },
    component: Master,
  },
]

export default routes
